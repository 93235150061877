

async function  GetTransactionsFromSheet(sheet_id, property, update) {
    var url = `https://script.google.com/macros/s/AKfycbzMUz93FecTWdcMi6Z-JdRWhZv1drLMn6vq5pq78BVE48cjtd6aZbEHEXZQ6XmIfFSbFw/exec?method=getTransactions&sheetid=${sheet_id}&property=${encodeURIComponent(property)}`
    fetch(url).then(response => { 
        let result = response.json();
        result.then(result => update(result))
    })
}

export default GetTransactionsFromSheet;