

async function  SubmitReceipt(properties, category, payableTo, description, amount, account, date, reimbursement, links) {
    var script_url = "https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?";
    var url = `${script_url}method=create&category=${category}&payableTo=${payableTo}&description=${description}&amount=${amount}&account=${account}&date=${date}&reimbursement=${reimbursement}`;
    for (let i = 0; i < links.length; i++) {
        url += `&link=${links[i]}`
    }
    for (let j = 0; j < properties.length; j++) {
        url += `&property=${encodeURIComponent(properties[j])}`
      }
    const response = await fetch(url);

    const result = await response.json();
    return result;
}

export default SubmitReceipt;