import dayjs from 'dayjs';

export const getTodaysDate = () => {
  let day = dayjs(new Date())
  return day;
}

export const getActiveStartDate = (d) => {
    let day = d.date(1);
    let newDay = day.month(day.month()-1);
    return newDay.format('YYYY-MM-DD');
  }

export const getActiveEndDate = (d) => {
    let day = d.date(1);
    return day.format('YYYY-MM-DD');
  }

export const getStartDate = (d) => {
    let day = d.date(1);
    return day.format('YYYY-MM-DD');
  }

export const getEndDate = (d) => {
    let day = d.date(1);
    let newDay = day.month(day.month()+1);
    return newDay.format('YYYY-MM-DD');
  }

export const getActiveMonth = () => {
    let d = dayjs(new Date());
    d = d.month(d.month() - 1);
    return d;
}