import { Typography, Divider, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import MainNav from "../Navigation/mainnav";
import { createTheme, FormControl, InputLabel, TextField, Button } from "@material-ui/core";
import GetProperties from "../Drive/get_properties";
import GetCategories from '../Drive/get_categories';
import GetAccounts from "../Drive/get_accounts";
import Tooltip from "@material-ui/core/Tooltip";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SubmitReceipt from '../Drive/submit_receipt';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkIcon from '@mui/icons-material/Work';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  normalSpacing: {
      paddingTop: '40px',
  },
  raisedLabel: {
    paddingTop: '20px',
  },
  textArea: {
    width: '80vw',
    maxWidth: '800px',
  }

}));

const formLabelsTheme = createTheme({
    overrides: {
      
      MuiFormLabel: {
        root: {
            fontSize: '30px',
            color: 'black'
        },
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      }
    }
  });

function ReceiptUpload() {
    const [files, setFiles] = useState();
    const [loadingProperties, setLoadingProperties] = React.useState(false);
    const [properties, setProperties] = useState([]);
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [account, setAccount] = useState("");
    const [accountList, setAccountList] = useState([]);
    const [amount, setAmount] = useState();
    const [payableTo, setPayableTo] = useState("");
    const [description, setDescription] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [date, setDate] = useState(dayjs(new Date()));
    const [reimbursement, setReimbursement] = useState(false);
    const [uploading, setUploading] = useState(false);
    const classes = useStyles();

    React.useEffect(() => {
      setLoadingProperties(true);
        GetProperties(doneLoadingProperties);
        GetCategories(setCategories);
        GetAccounts(setAccountList);
    }, [])

    const doneLoadingProperties = (values) => {
      let props = []
      for (let i = 0; i < values.length; i++) {
        let p = {}
        p.property = values[i];
        p.status = false;
        props.push(p);
      }
      setProperties(props);
      setLoadingProperties(false);
    }

    const clearAlerts = () => {
      if (successMessage != "") {
        setSuccessMessage("");
      }
      if (errorMessage != "") {
        setErrorMessage("");
      }
    }

    const handlePropertyChange = (event, index) => {
      let newProperties = [...properties];
      newProperties[index].status = !newProperties[index].status;
      setProperties(newProperties);
    }

    const handleCategoryChange = (event, value) => {
      clearAlerts();
        setCategory(value);
    }

    const handleAccountChange = (event, value) => {
      clearAlerts();
        setAccount(value);
    }

    const handleDescriptionChange = (event) => {
      clearAlerts();
      setDescription(event.target.value);
    }

    const handlePayableToChange = (event) => {
      clearAlerts();
      setPayableTo(event.target.value);
    }

    const handleAmountChange = (event) => {
      clearAlerts();
      setAmount(event.target.value);
    }

    const resetProperties = () => {
      let newProperties = [...properties];
      for (let i = 0; i < newProperties.length; i++) {
        newProperties[i].status = false;
      }
      setProperties(newProperties);
    }

    const getProperties = () => {
      let res = [];
      for (let i = 0; i < properties.length; i++) {
        if(properties[i].status) {
          res.push(properties[i].property);
        }
      }
      return res;
    }


    const handleSubmit = async (e) => {
        clearAlerts();
        var selectedProperties = getProperties();

        if (files != undefined && selectedProperties.length != 0 && category != "" && payableTo!= "" && description != "" && amount != 0 && account != "") {
          setUploading(true);
          e.preventDefault();
          
          let urls = []

          var token;
          try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("client_id", "1073021396518-6la0s51pgnctopn5f2n07f8bq0fpmn47.apps.googleusercontent.com");
            urlencoded.append("client_secret", "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");
            urlencoded.append("refresh_token", "1//04frBje1_qF5NCgYIARAAGAQSNwF-L9IrGCWlz_DoRYn0WyrGSdaxRV7OndQ9J0Wq_WCyeehicWP6afNEq64JFFjSV5-DM1o5kcI");
            urlencoded.append("grant_type", "refresh_token");
        
            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
        
            const response = await fetch("https://www.googleapis.com/oauth2/v4/token", requestOptions);
            const result = await response.json();
            token = result.access_token;
          }
          catch (error) {
            setErrorMessage(`Unable to authenticate with google servers.`);
            setUploading(false);
            return;
          }
 
          // upload all files
          try {
            for (let i = 0; i < files.length; i++) {

              var fileMetaData = {
                name: files[i].data.name,
                parents: ["1eVPo0LpTWDgK7jUDyQkhSLUl0ipoX7_u"],
              }

              var media = {
                mimeType: files[i].data.type,
                body: files[i].data,
              }


              let form = new FormData();
              form.append('metadata', new Blob([JSON.stringify(fileMetaData)], { type: 'application/json' }));
              form.append('file', media.body, media.mimeType);

              let header = new Headers;
              header.append('Authorization', 'Bearer ' + token);

              const upload_response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                  method: 'POST',
                  headers: header,
                  body: form,
              })
              const upload_result = await upload_response.json();
              urls.push(upload_result.id);
            }
          }
          catch (error) {
            setErrorMessage(`Failed to upload file(s): ${error.message}`);
            setUploading(false);
            return;
          }

          const result = await SubmitReceipt(selectedProperties, encodeURIComponent(category), encodeURIComponent(payableTo), encodeURIComponent(description), -1*amount, account, date.format('YYYY-MM-DD'), reimbursement, urls);
        
          
          if (result.succeeded) {
            setSuccessMessage(`Uploaded receipt for ${Array.from(selectedProperties)}`)
            setFiles(undefined);
            resetProperties();
            setCategory("");
            setPayableTo("");
            setDescription("");
            setAmount("");
            setAccount("");
            setReimbursement(false);
            setDate("");
          }
          else {
            setErrorMessage(`Failed to upload. Please try again or contact your admin.`)
          }
          setUploading(false);
        }
        else {
          let error = "";
          if (files == undefined) {
            error += "Missing Receipt \n"
          }
          if (selectedProperties.length == 0) {
            error += "Missing Property \n"
          }
          if (category == "") {
            error += "Missing Category \n"
          }
          if (payableTo == "") {
            error += "Missing Payable To \n"
          }
          if (description == "") {
            error += "Missing Description \n"
          }
          if (amount == 0) {
            error += "Missing Amount \n"
          }
          if (account == "") {
            error += "Missing Account \n"
          }
          setErrorMessage(error);
        }
    };

    const handleDateChange = (value) => {
      console.log(value);
      setDate(value);
    }


    const handleFileChange = (e) => {
        clearAlerts();
        var files = [];
        for (let i = 0; i < e.target.files.length; i++) {
          let file = {
          preview: URL.createObjectURL(e.target.files[i]),
          data: e.target.files[i],
          }
          files.push(file);
        }
        setFiles(files);
    };
    return (
        <>
        <MainNav />
        <Divider sx={{ borderBottomWidth: 3}} />
        <Box style={{paddingLeft: '35px', paddingTop: '20px'}}>
            <Typography variant="h5" >Receipts</Typography>

            <MuiThemeProvider theme={formLabelsTheme}>
            <form noValidate autoComplete="off" >
              <Box className={classes.normalSpacing}>
                  <Tooltip title="defaults to today's date">
                    <InputLabel shrink htmlFor="age-native-simple">
                      Date (Optional)
                    </InputLabel>
                    </Tooltip>
                    {/* <TextField
                      id="date"
                      // label="Date"
                      type="date"
                      onChange={(event) => setDate(event.target.value)}
                      value={date}
                      // className={classes.textField}
                      InputLabelProps={{
                      shrink: true,
                      }}
                      
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs} className={classes.datepicker}>
                      <DatePicker 
                          // label="Month" 
                          // views={['month', 'year']} 
                          value={date}
                          // className={classes.datepicker}
                          onChange={handleDateChange}
                          InputLabelProps={{
                            shrink: true,
                            }}
                          />
                    </LocalizationProvider>
                </Box>
                <Box className={classes.normalSpacing}>
                    <InputLabel required shrink htmlFor="age-native-simple" style={{paddingBottom: '10px'}}>
                      Upload
                    </InputLabel>
                    <label htmlFor="upload-photo">
                      <input
                        multiple
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="upload-photo"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Tooltip title="Upload Receipt" placement="right">
                          <Button type="submit"  variant="contained" component="div" 
                          style={{
                              backgroundColor: 'black', 
                              padding: '15px 20px 15px 20px'}}
                          >
                              <Typography sx={{color: 'white', fontWeight: '700', fontSize:'25px', transform: 'scale(1,0.8)'}}>Add File Here</Typography>
                          </Button>
                      </Tooltip>
                      {files != undefined && (files.map((f => (<Typography>Selected: {f.data.name}</Typography>))))}
                    </label>
                </Box>
                <Box className={classes.normalSpacing}>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Property
                    </InputLabel>
                    {/* <Autocomplete
                        value = {property}
                        id="combo-box-demo"
                        sx={{width: '300px'}}
                        options={properties}
                        onChange={handlePropertyChange}
                        disableClearable
                        renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }}/>}
                    />    */}
                    {loadingProperties ? (<CircularProgress />):(
                    <FormGroup sx={{display: 'block'}}>
                    {properties.map(((p, index) => {
                      return (
                      <FormControlLabel sx={{display: 'block'}} control={
                        <Checkbox 
                        checked={p.status}
                        onChange={(event) => handlePropertyChange(event, index)}
                        />
                        // <MyCheckBox status={p.status} handlePropertyChange={handlePropertyChange} index={index}/>
                      } label={p.property} />
                    )}))}
                    </FormGroup>
                    )}
                </Box>
                <Box className={classes.normalSpacing}>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Category
                    </InputLabel>
                    <Autocomplete
                    variant="outlined"
                        value = {category}
                        id="combo-box-demo"
                        sx={{width: '300px'}}
                        options={categories}
                        onChange={handleCategoryChange}
                        disableClearable
                        renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }}/>}
                    />   
                </Box>
                <Box className={classes.normalSpacing}>
                    <FormControl>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Payable To
                    </InputLabel>
                        <TextField
                            className={`${classes.raisedLabel} ${classes.textArea}`}
                            value={payableTo}
                            variant="outlined"
                            id="standard-name"
                            margin="normal"
                            onChange={handlePayableToChange}
                            />
                    </FormControl>
                </Box>
                <Box className={classes.normalSpacing}>
                    <FormControl>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Description
                    </InputLabel>
                        <TextField
                            className={`${classes.raisedLabel} ${classes.textArea}`}
                            value={description}
                            variant="outlined"
                            id="standard-name"
                            margin="normal"
                            onChange={handleDescriptionChange}
                            />
                    </FormControl>
                </Box>
                <Box className={classes.normalSpacing}>
                    <FormControl>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Amount
                    </InputLabel>
                    <TextField 
                      className={classes.raisedLabel}
                      id="quantity"  
                      type="number"
                      value={amount}
                      style={{width: '300px', paddingTop: '30px'}}
                      onChange={handleAmountChange}
                      InputProps={{ 
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      inputProps={{
                        inputMode: 'decimal' 
                      }}
                      />
                    </FormControl>
                </Box>
                <Box className={classes.normalSpacing}>
                    <InputLabel required shrink htmlFor="age-native-simple">
                      Account
                    </InputLabel>
                    <Autocomplete
                    variant="outlined"
                        value = {account}
                        id="combo-box-demo"
                        sx={{width: '300px'}}
                        options={accountList}
                        onChange={handleAccountChange}
                        disableClearable
                        renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }}/>}
                    />   
                </Box>
                <Box className={classes.normalSpacing}>
                <InputLabel required shrink htmlFor="age-native-simple">
                      Reimbursement
                    </InputLabel>
                <FormControlLabel
                  label={reimbursement ? "Getting money back $$" : "All on me"}
                  control={
                    <Checkbox
                    checked={reimbursement}
                    label="Reimbursement"
                    icon={<WorkOutlineIcon />}
                    checkedIcon={<WorkIcon sx={{color: 'green'}}/>}
                    onChange={() => setReimbursement(!reimbursement)}
                  />
                  }
                />
                </Box>
                <Box className={classes.normalSpacing} sx={{paddingBottom: '100px'}}>
                  <Button 
                  type="submit"  
                  variant="contained" 
                  component="div" 
                  style={{
                      backgroundColor: 'black', 
                      padding: '15px 20px 15px 20px'}}
                  onClick={handleSubmit}
                  >
                      <Typography sx={{color: 'white', fontWeight: '700', fontSize: '25px', transform: 'scale(1,0.8)'}}>Submit</Typography>
                  </Button>
                  {uploading && (<LinearProgress sx={{width: '80%', marginTop: '20px'}} />)}
                  {successMessage != "" && (<Alert severity="success" sx={{width: '80%', marginTop: '20px', overflowWrap: 'break-word'}}>{successMessage}</Alert>)}
                  {errorMessage != "" && (<Alert severity="error" sx={{whiteSpace: 'pre-line' , width: '80%', marginTop: '20px'}}>{errorMessage}</Alert>)}
                </Box>
            </form>
          </MuiThemeProvider>

        </Box>
        </>
    );
    }

    export default ReceiptUpload;