import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MenuIcon from '@mui/icons-material/Menu';
import WorkIcon from '@mui/icons-material/Work';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    nav: {
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        // margin: 'auto',
    },
    largeIcon: {
        height: '50px',
    }
}));


export default function MainNav (props) {
    const [state, setState] = React.useState({
        left: false,
    });
    const navigate = useNavigate();
    const classes = useStyles();
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
    
        setState({ ...state, [anchor]: open });
    };
    
    const list = (anchor) => (
        <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, paddingLeft: '20px' }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        >
        <List>
            <ListItem key={"Receipt Upload"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/receipts')}
                >
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary={"Receipts"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Transactions"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/transactions')}
                >
                <ListItemIcon>
                    <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={"Transactions"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Upload"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/upload')}
                >
                <ListItemIcon>
                    <DriveFolderUploadIcon />
                </ListItemIcon>
                <ListItemText primary={"Upload"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Audit"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/audit')}
                >
                <ListItemIcon>
                    <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText primary={"Audit"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Reimbursements"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/reimb')}
                >
                <ListItemIcon>
                    <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={"Reimbursements"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Dashboard"} disablePadding>
                <ListItemButton
                onClick={() => navigate('/dashboard')}
                >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"T12"} />
                </ListItemButton>
            </ListItem>
        </List>
        </Box>
    );
    
    return (
        <div>
            <React.Fragment key={'left'}>
            <div className={classes.nav}>
                <Button onClick={toggleDrawer('left', true)}>
                    <MenuIcon fontSize="large" sx={{color: 'black'}}/>
                </Button>
                <Typography className={classes.title} variant="h4" sx={{fontWeight: '700', letterSpacing: '0.05em', transform: 'scale(1,0.8)'}}>LANDMARQUE NATIONAL</Typography>
            </div>
            <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
            </React.Fragment>
        </div>
    );
    }
