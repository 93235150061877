import * as React from 'react';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {getActiveMonth, getStartDate, getEndDate} from '../Util/get_dates';
import { CircularProgress, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { save } from '../Redux/property_slice';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from '@material-ui/core';
import GetCategories from '../Drive/get_categories';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import {ToggleButton, ToggleButtonGroup } from "@mui/material";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { IconButton } from '@material-ui/core';
import SaveReimbursements from '../Drive/save_reimbursements';

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
      height: '31px',
      // paddingRight: '2vw',
    },
    textField2: {
      width: 200,
      height: '31px',
      // paddingRight: '2vw',
    },
    propertyDropdownContainer: {
      width: '15vw',
      // paddingRight: '2vw',
      height: '100%'
    },
    propertyDropdown: {
      // paddingRight: '2vw',
      height: '100%'
    },
    select: {
      textAlign: 'middle',
      height: '32px',
      justifyContent: 'center',
    },
    paper: {
      width: '92%', 
      padding: '2vw',
    },
    amountField: {
      paddingRight: '10px',
    },
    applyButton: {
      marginTop: '10px',
      marginBottom: '10px',
    }
  }));


const downloadReimbursementFile = (orientation, format) => {
  let ori = orientation == "portrait";
  window.open(`https://docs.google.com/spreadsheets/d/1tQ3t0P4ksSWhanBmH2YVHW4c6BYOT68Z6-kCCiggbBY/export?gid=0&format=${format}&portrait=${ori}`, "_system");
};

  


export default function FilterCategories(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(getActiveMonth());
    const [property, setProperty] = React.useState(useSelector(state => state.property.value));
    const [category, setCategory] = React.useState("");
    const [changes, setChanges] = React.useState(false);
    const [updatingCategories, setUpdatingCategories] = React.useState(false);
    const [orientation, setOrientation] = React.useState('landscape');
    const [fileType, setFileType] = React.useState('pdf');
    const [downloading, setDownloading] = React.useState(false);

    React.useEffect(() => {
      applyParameters();
    }, [property])

    React.useEffect(() => {
      applyParameters();
    }, [props.count])

    const handleDropdownChange = (event, value) => {
        // setChanges(true);
        setProperty(value);
        dispatch(save(value));
    }

    const handleDateChange = (value) => {
      setChanges(true);
      setStartDate(value);
    }

    const handleCategoryChange = (event, value) => {
        setChanges(true);
        setCategory(value);
    }

    const handleOrientation = (event, newOrientation) => {
      setOrientation(newOrientation);
    };

    const handleFileType = (event, newFileType) => {
      setFileType(newFileType);
    }

    const applyParameters = () => {
      if (props.getWithParameters) {
        props.updatingSelection(true);
        setChanges(false);
        props.getWithParameters(getStartDate(startDate), getEndDate(startDate), property, category, props.setRows);
      }
    }


    const clearProperty = () => {
      setProperty("");
      props.updatingSelection(true);
      props.getWithParameters(getStartDate(startDate), getEndDate(startDate), "", category, props.setRows);
    }

    const clearCategory = () => {
      setCategory("");
      props.updatingSelection(true);
      props.getWithParameters(getStartDate(startDate), getEndDate(startDate), property, "", props.setRows);
    }

    const getNewCategories = () => {
      setUpdatingCategories(true);
      GetCategories(handleDoneGettingCategories);
    }

    const handleDoneGettingCategories = (values) => {
      props.updateCategories(values);
      setUpdatingCategories(false);
    }

    const download = () => {
      setDownloading(true);
      SaveReimbursements(getStartDate(startDate), getEndDate(startDate), property, doneDownloading);
    }

    const doneDownloading = (res) => {
      downloadReimbursementFile(orientation, fileType);
      setDownloading(false);
    }

return (
  <Box>
          <Box
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{paddingLeft: '10px'}}
          >
            <Stack direction="row" spacing={1} sx={{float: 'left', width: '100%'}}>
              {!props.showExcluded && (
                <Chip color="success" label={"Excluded Transactions Hidden"}/>
              )}
              {!props.importButton && (<Chip color="success" label={`${startDate.format('MMMM')}`}/>)}
              {!props.importButton && !changes && property !== "" && (
                <Chip color="info" label={`Property: ${property}`} onDelete={clearProperty}/>
              )}
              {!props.importButton && !changes && category !== "" && (
                <Chip color="info" label={`Category: ${category}`} onDelete={clearCategory}/>
              )}
              {!props.importButton && changes && <Chip color="error" label={"Changed yet to be applied"}/>}
            </Stack>
          </Box>
          <Box>
              <Box component="div" sx={{ width: '100%', display: 'flex', gap: '2vw', float: 'left', paddingTop: '2vh', paddingBottom: '2vh'}}>
              {!props.importButton && (
                <FormControlLabel
                control={<Switch checked={props.showExcluded} onChange={props.handleShowExcludedChange} />}
                label="Show Excluded"
                labelPlacement="start"
                component="div"
                />)}
                {props.importButton && <Typography style={{paddingTop: '5px', paddingLeft: '10px'}}>{props.length} Transaction(s)</Typography>}
                {props.importButton && !updatingCategories && (<Tooltip title="Refresh Categories" placement="right">
                  <RefreshIcon 
                  sx={{ fontSize: "40px", color: '#808080', "&:hover": {color: "black" }}} 
                  onClick={getNewCategories}
                  />
                  </Tooltip>)}
                  {props.importButton && updatingCategories && (<CircularProgress />)}
                {!props.importButton && (<>
                <LocalizationProvider dateAdapter={AdapterDayjs} className={classes.datepicker}>
                  <DatePicker 
                      label="Month" 
                      views={['month', 'year']} 
                      value={startDate}
                      className={classes.datepicker}
                      onChange={handleDateChange}
                      InputLabelProps={{
                        shrink: true,
                        }}
                      />
                </LocalizationProvider>
                <Autocomplete
                  disablePortal
                  value = {property}
                  id="combo-box-demo"
                  options={props.properties}
                  sx={{ width: 300 }}
                  disableClearable
                  onChange={handleDropdownChange}
                  renderInput={(params) => <TextField {...params} label="Property" InputLabelProps={{ shrink: true }} />}
                />   
                <Autocomplete
                  disablePortal
                  value = {category}
                  id="combo-box-demo"
                  disabled={props.disableCategory}
                  options={props.categories}
                  sx={{ width: 300 }}
                  disableClearable
                  onChange={handleCategoryChange}
                  renderInput={(params) => <TextField {...params} label="Category" InputLabelProps={{ shrink: true }} />}
                />   
                <Button 
                variant="contained" 
                color="primary"
                className={classes.applyButton}
                onClick={applyParameters}
                > Apply</Button>
                </>)}

            </Box>
          </Box>
          {props.downloadOptions && (
          <Box sx={{paddingLeft: '15px', paddingBottom: '10px'}}>
              <ToggleButtonGroup
              value={fileType}
              exclusive
              onChange={handleFileType}
              // aria-label="text alignment"
              style={{height: '50px'}}
              >
                  <ToggleButton value="pdf" aria-label="centered">
                      <Tooltip title={"select pdf format"}>
                          <Typography>PDF</Typography>
                      </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="csv" aria-label="left aligned">
                      <Tooltip title={"select csv format"}>
                          <Typography>CSV</Typography>
                      </Tooltip>
                  </ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
              value={orientation}
              exclusive
              onChange={handleOrientation}
              // aria-label="text alignment"
              style={{paddingLeft: '1vw', height: '50px', verticalAlign: 'middle'}}
              >
                  <ToggleButton value="landscape" aria-label="centered">
                      <Tooltip title={"Landscape"}>
                          <CropLandscapeIcon />
                      </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="portrait" aria-label="left aligned">
                      <Tooltip title={"Portrait"}>
                          <CropPortraitIcon />
                      </Tooltip>
                  </ToggleButton>
              </ToggleButtonGroup>
              {downloading ? (<div 
                        style={{display: 'inline'}}
                        ><IconButton><CircularProgress /></IconButton></div>):(
                    <IconButton 
                    size="large" 
                    style={{borderRadius: 10,}}
                    sx={{"&:hover": {backgroundColor: "transparent" }}}
                    onClick={download}
                    >
                        <Tooltip title={"Download"}>
                            <SaveAltIcon sx={{fontSize: "40px", color: '#808080', "&:hover": {color: "black" }}} />
                        </Tooltip>
                        </IconButton>
                    )}
          </Box>
          )}
        </Box>
)
}

