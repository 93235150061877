import { createSlice } from '@reduxjs/toolkit';

export const propertySlice = createSlice({
    name: "property",
    initialState: {
        value: "",
    },
    reducers: {
        save: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const {save} = propertySlice.actions

export default propertySlice.reducer