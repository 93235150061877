async function  AddSingleIncome(date, property, amount, category, handleDone) {
    var url = `https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?method=addSingleIncome&date=${date}&amount=${amount}&property=${encodeURIComponent(property)}&category=${encodeURIComponent(category)}`
    fetch(url).then(response => { 
        let result = response.json();
        result.then(res => {
            if (res.succeeded) {
                handleDone()
            }
        })
    })
}

export default AddSingleIncome;