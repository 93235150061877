import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import GoogleDriveImage from '../../assets/images/google-drive.png'
import { style } from '../Gapi/SelectSource/styles';
import { loadGapiInsideDOM } from 'gapi-script';
import MainNav from '../Navigation/mainnav';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';

const NewDocumentWrapper = styled.div`
  ${style}
`;

// Client ID and API key from the Developer Console
const CLIENT_ID = "1073021396518-6la0s51pgnctopn5f2n07f8bq0fpmn47.apps.googleusercontent.com";
const API_KEY = "AIzaSyBvCBYeCsnjExHU-uYJD7kNuq9Ah801Y4g";

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.

const SCOPES = "https://www.googleapis.com/auth/drive";



export default function SignIn(props) {
    var GoogleAuth;
    const[gapiloaded, setgapiloaded] = useState(false);
    const[failedSignIn, setFailedSignIn] = useState(false);
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        loadGapiInsideDOM().then(result => {
            setgapiloaded(true);
            handleClientLoad();
        });
    }, [])
  
    /**
     *  Sign in the user upon button click.
     */
    const handleAuthClick = (event) => {
      setFailedSignIn(false);
      setIsLoadingGoogleDriveApi(true);
      window.gapi.auth2.getAuthInstance().signIn();
    };
  
    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
      setIsLoadingGoogleDriveApi(true);
      window.gapi.client
        .init({
          'apiKey': API_KEY,
          'clientId': CLIENT_ID,
          'discoveryDocs': DISCOVERY_DOCS,
          'scope': SCOPES,
        })
        .then(
          function () {
            let GoogleAuth = window.gapi.auth2.getAuthInstance();

            // Listen for sign-in state changes.
            GoogleAuth.isSignedIn.listen(setSigninStatus);
            setSigninStatus();
          },
          function (error) {}
        );
    };

    function setSigninStatus() {
        var user = window.gapi.auth2.getAuthInstance().currentUser.get();
        var isAuthorized = user.hasGrantedScopes(SCOPES);
        if (isAuthorized) {
            setIsLoadingGoogleDriveApi(false);
            if (location.key == "default") {
              navigate("/");
            }
            else {
              navigate(-1);
            }
        } else {
            // handleAuthClick();
            setFailedSignIn(true);
            setIsLoadingGoogleDriveApi(false);
        }
      }


    function handleClientLoad() {
        // Load the API's client and auth2 modules.
        // Call the initClient function after the modules load.
        window.gapi.load('client:auth2', initClient);
      }
  
  
    return (
    <>
    <MainNav/>
    {failedSignIn && (<Alert severity="error">Failed to sign in. Try logging in again or contact your administrator for help</Alert>)}
      {gapiloaded && (<NewDocumentWrapper>
        <Row gutter={16} className="custom-row">
          <Col span={16}>
            <Spin spinning={isLoadingGoogleDriveApi} style={{ width: '100%' }}>
              <div onClick={() => handleAuthClick()} className="source-container">
                <div className="icon-container">
                  <div className="icon icon-success">
                    <img height="80" width="80" src={GoogleDriveImage} />
                  </div>
                </div>
                <div className="content-container">
                  <p className="title">Google Sign In</p>
                  <span className="content">Sign in to google to continue</span>
                </div>
              </div>
            </Spin>
          </Col>
        </Row>
      </NewDocumentWrapper>)}
      </>
    );
}