async function  UpdateIncludeSingleInSheet(value,n, update) {
    var url = `https://script.google.com/macros/s/AKfycbzMUz93FecTWdcMi6Z-JdRWhZv1drLMn6vq5pq78BVE48cjtd6aZbEHEXZQ6XmIfFSbFw/exec?method=updateIncludeSingle&value=${value}&n=${n}`

    fetch(url).then(response => { 
        let result = response.json();
        result.then(res => {
            update(res);
        })
    })
}

export default UpdateIncludeSingleInSheet