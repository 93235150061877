
async function AddRecurringIncome(startDate, endDate, property, amount, frequency, category, handleDone) {
        var url = `https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?method=addRecurringIncome&startDate=${startDate}&endDate=${endDate}&amount=${amount}&property=${encodeURIComponent(property)}&frequency=${frequency}&category=${encodeURIComponent(category)}`
        fetch(url).then(response => { 
            let result = response.json();
            result.then(res => {
                if (res.succeeded) {
                    handleDone()
                }
            })
        })
    }
    
 export default AddRecurringIncome;