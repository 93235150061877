import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import GetDuplicateTransactions from '../Drive/get_duplicate_transactions';
import GetDuplicateTransactionsWithParameters from '../Drive/get_duplicate_transactions_with_parameters';
import GetCheckTransactionsWithParameters from '../Drive/get_check_transactions_with_parameters';
import GetIncorrectCategoryTransactionsWithParameters from '../Drive/get_incorrect_category_transactions_with_parameters';
import GetCategories from '../Drive/get_categories';
import UpdateInclude from '../Drive/update_include';
import UpdateCategory from '../Drive/update_category';
import MainNav from '../Navigation/mainnav';
import { useNavigate } from 'react-router-dom';
import TransactionList from '../Bodies/transactions_list';
import GetProperties from '../Drive/get_properties';
import Typography from '@mui/material/Typography';
import UpdateIncludeSingle from '../Drive/update_include_single';
import { styled } from '@mui/material/styles';
import { CircularProgress, Divider, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import {getActiveStartDate, getActiveEndDate} from '../Util/get_dates';
import {Alert} from '@mui/material';
import { useSelector } from 'react-redux';


export default function Audit (props) {
    const [rows, setRows] = React.useState([]);
    const [gettingDuplicateRows, setGettingDuplicateRows] = React.useState(false);
    const [checkRows, setCheckRows] = React.useState([]);
    const [incorrectCategoryRows, setIncorrectCategoryRows] = React.useState([]);
    const [gettingCheckRows, setGettingCheckRows] = React.useState(false);
    const [gettingIncorrectCategoriesRows, setGettingIncorrectCategoryRows] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [signedIn, setSignedIn] = React.useState(false);
    const [properties, setProperties] = React.useState([]);
    const [showIncorrectCategories, setShowIncorrectCategories] = React.useState(false);
    const [showChecks, setShowChecks] = React.useState(false);
    const [showDuplicates, setShowDuplicates] = React.useState(false);
    const navigate = useNavigate();
    const property = useSelector(state => state.property.value);

    useEffect(() => {
        if (window.gapi.auth2 == undefined || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
          navigate('/signin')
        }
        else {
            setSignedIn(true);
            GetCategories(setCategories);
            GetProperties(setProperties);
            setGettingDuplicateRows(true);
            let d = dayjs(new Date());
            GetDuplicateTransactionsWithParameters(getActiveStartDate(d), getActiveEndDate(d),property , "", doneGettingDuplicateRows)
            setGettingCheckRows(true);
            GetCheckTransactionsWithParameters(getActiveStartDate(d), getActiveEndDate(d), property, "", doneGettingCheckRows);
            setGettingIncorrectCategoryRows(true);
            GetIncorrectCategoryTransactionsWithParameters(getActiveStartDate(d), getActiveEndDate(d), property, "", doneGettingIncorrectCategoryRows);
        }
      }, []);

      const doneGettingCheckRows = (values) => {
        setCheckRows(values);
        setGettingCheckRows(false);
      }

      const doneGettingDuplicateRows = (values) => {
        setRows(values);
        setGettingDuplicateRows(false);
      }

      const doneGettingIncorrectCategoryRows = (values) => {
        setIncorrectCategoryRows(values);
        setGettingIncorrectCategoryRows(false);
      }


      return (
        <Box sx={{ width: '100%' }}>
          <MainNav/>
          <Divider sx={{ borderBottomWidth: 3}}/>
          <Typography variant="h5" style={{paddingLeft: '4vw', paddingTop: '1vw', paddingBottom: '1vw'}}>{`Audit Transactions  ${property}`}</Typography>
          <Typography variant="subtitle" style={{paddingLeft: '4vw', paddingTop: '1vw', paddingBottom: '1vw'}}>Careful! Results may be for the active month and NOT the entire year.</Typography>
          {signedIn && (
            <Box sx={{paddingRight: '4vw', paddingLeft: '4vw'}}>
                <Box 
                component="div" 
                sx={{ display: 'flex', "&:hover": {cursor: 'default', backgroundColor: '#F5F5F580', borderRadius: 4}}}
                onClick={() => setShowIncorrectCategories(!showIncorrectCategories)}
                >
                  <IconButton 
                    sx={{"&:hover": {backgroundColor: "transparent" }}}
                    >
                    {showIncorrectCategories ? (<ExpandLessIcon sx={{fontSize:30}}/>):(<ExpandMoreIcon sx={{fontSize:30}}/>)}
                    </IconButton>
                    { gettingIncorrectCategoriesRows ? (<CircularProgress />):(
                    <Tooltip title={`There are ${incorrectCategoryRows.length} transactions with incorrect categories`}>
                      <Alert severity={incorrectCategoryRows.length === 0 ? "success": "error"} sx={{margin: '5px'}}>{incorrectCategoryRows.length}</Alert>
                    </Tooltip>
                    )}
                    <Tooltip title={`Transactions are for the entire year, not just the selected month`}>
                      <Alert severity="warning" sx={{margin: '5px', width: '80px'}}>Year</Alert>
                    </Tooltip>
                    <Typography variant="h6" style={{paddingTop: '1vw', paddingBottom: '1vw', paddingLeft: '1vw'}}>Invalid Categories</Typography>
                </Box>
                <Divider sx={{ borderBottomWidth: 2}}/>
                {showIncorrectCategories && (
                <Box sx={{paddingLeft: '2vw'}}>
                  <TransactionList disableCategory gettingRows={gettingIncorrectCategoriesRows} rows={incorrectCategoryRows} setRows={setIncorrectCategoryRows} categories={categories} properties={properties} getWithParameters={GetIncorrectCategoryTransactionsWithParameters} UpdateInclude={UpdateInclude} UpdateIncludeSingle={UpdateIncludeSingle} UpdateCategory={UpdateCategory} title={"Transactions with invalid categories"}/>
                </Box>
                  )}
                <Box 
                component="div" 
                sx={{ display: 'flex', "&:hover": {cursor: 'default', backgroundColor: '#F5F5F580', borderRadius: 4}}}
                onClick={() => setShowChecks(!showChecks)}
                >
                    <IconButton 
                    sx={{"&:hover": {backgroundColor: "transparent" }}}
                    >
                    {showChecks ? (<ExpandLessIcon sx={{fontSize:30}}/>):(<ExpandMoreIcon sx={{fontSize:30}}/>)}
                    </IconButton>
                    { gettingCheckRows ? (<CircularProgress />):(
                    <Tooltip title={`There are ${checkRows.length} transactions matching check amounts`}>
                      <Alert severity={checkRows.length === 0 ? "success": "error"} sx={{margin: '5px'}}>{checkRows.length}</Alert>
                    </Tooltip>
                    )}
                    <Tooltip title={`Transactions are for the selected month, not the entire year`}>
                      <Alert severity="warning" sx={{margin: '5px', width: '80px'}}>Month</Alert>
                    </Tooltip>
                    <Typography variant="h6" style={{paddingTop: '1vw', paddingBottom: '1vw', paddingLeft: '1vw'}}>Checks</Typography>
                </Box>
                <Divider sx={{ borderBottomWidth: 2}}/>
                {showChecks && (
                <Box sx={{paddingLeft: '2vw'}}>
                  <TransactionList gettingRows={gettingCheckRows} rows={checkRows} setRows={setCheckRows} categories={categories} properties={properties} getWithParameters={GetCheckTransactionsWithParameters} UpdateInclude={UpdateInclude} UpdateIncludeSingle={UpdateIncludeSingle} UpdateCategory={UpdateCategory} title={"Transactions Matching Checks"}/>
                </Box>
                )}
                <Box 
                component="div" 
                sx={{ display: 'flex', "&:hover": {cursor: 'default', backgroundColor: '#F5F5F580', borderRadius: 4}}}
                onClick={() => setShowDuplicates(!showDuplicates)}
                >
                  <IconButton 
                    sx={{"&:hover": {backgroundColor: "transparent" }}}
                    >
                    {showDuplicates ? (<ExpandLessIcon sx={{fontSize:30}}/>):(<ExpandMoreIcon sx={{fontSize:30}}/>)}
                    </IconButton>
                    { gettingDuplicateRows ? (<CircularProgress />):(
                    <Tooltip title={`There are ${rows.length} transactions with duplicate amounts`}>
                      <Alert severity={rows.length === 0 ? "success": "error"} sx={{margin: '5px'}}>{rows.length}</Alert>
                    </Tooltip>
                    )}
                    <Tooltip title={`Transactions are for the selected month, not the entire year`}>
                      <Alert severity="warning" sx={{margin: '5px', width: '80px'}}>Month</Alert>
                    </Tooltip>
                    <Typography variant="h6" style={{paddingTop: '1vw', paddingBottom: '1vw', paddingLeft: '1vw'}}>Duplicate Transactions</Typography>
                </Box>
                <Divider sx={{ borderBottomWidth: 2}}/>
                {showDuplicates && (
                <Box sx={{paddingLeft: '2vw'}}>
                  <TransactionList gettingRows={gettingDuplicateRows} rows={rows} setRows={setRows} categories={categories} properties={properties} getWithParameters={GetDuplicateTransactionsWithParameters} UpdateInclude={UpdateInclude} UpdateIncludeSingle={UpdateIncludeSingle} UpdateCategory={UpdateCategory} title={"Duplicate Transactions"}/>
                </Box>
                  )}
            </Box>
          )}
        </Box>
      );
}