async function  UpdateInclude(selected, value, update) {
    var url = `https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?method=updateInclude&value=${value}`
    for(let i = 0; i < selected.length; i++) {
        url += `&n=${selected[i]}`
    }
    fetch(url).then(response => { 
        let result = response.json();
        result.then(result => update(result))
    })
}

export default UpdateInclude