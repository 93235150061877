import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import GetT12 from '../Drive/getT12';
import Button from '@mui/material/Button';
import { LinearProgress } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import dayjs from 'dayjs';
import {getActiveStartDate, getActiveEndDate} from '../Util/get_dates';
import GetIncorrectCategoryTransactionsWithParameters from '../Drive/get_incorrect_category_transactions_with_parameters';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function RowSection (props) {

    let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
     }

    const showExpanded = (data) => {
        if (!data.header) {
            return true;
        }
        else {
            return props.expanded;
        }
    }

    return (
        <>
            {props.section.header && 
                (<TableRow 
                key={props.section.title}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell 
                    component="th" 
                    scope="row"
                    style={{
                        fontWeight: '700', 
                        paddingLeft: '1vw',
                        position: 'sticky',
                        background: 'white',
                        zIndex: 800,
                        left: 0,
                    }}
                    onClick={props.updateExpanded}
                    key={`${props.section.title}_cell`}
                    >
                    {props.section.title}
                    <Button style={{float:'right', padding: 0}} >
                        {props.expanded ? (<ArrowDropUpIcon style={{color: 'black'}}/>):(<ArrowDropDownIcon style={{color: 'black'}}/>)}
                    </Button>
                    </TableCell>
                </TableRow>
            )}
            {showExpanded(props.section) && props.section.rows.map((row) => (
                <TableRow
                    key={`${row.title}_header`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell 
                    component="th" 
                    scope="row"
                    style={{
                        paddingLeft: props.section.header ? '2vw': '1vw',
                        fontWeight: props.section.header ? '400': '700',
                        position: 'sticky',
                        zIndex: 800,
                        left: 0,
                        background: 'white',
                    }}
                    key={row.title}
                    >
                    {row.title}
                    </TableCell>
                    {row.data.map((monthdata, index) => {
                        if (monthdata == "0") {
                            return (
                            <TableCell 
                            align="right"
                            key={index}
                            style={{
                                fontWeight: props.section.header ? '400': '700',
                            }}
                            > — </TableCell>)
                        }
                        else {
                        return (
                        <TableCell 
                        align="right"
                        key={index}
                        style={{
                            fontWeight: props.section.header ? '400': '700',
                        }}
                        >{monthdata.toLocaleString('en-US', formatting_options )}</TableCell>)
                        
                    }})}
                </TableRow>
            ))}
        </>
    )
}

function SectionWrapper(props) {
    const [expanded, setExpanded] = React.useState(props.section.expanded)

    React.useEffect(() => {
        setExpanded(props.section.expanded)
    }, [props.section])

    const updateExpanded = () => {
        setExpanded(!expanded);
    }

    return (
        <RowSection section={props.section} expanded={expanded} updateExpanded={updateExpanded}></RowSection>
    )

}


export default function T12Table(props) {
    const [months, setMonths] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        GetT12(props.property, props.month, props.year, handleDataUpdate)
        let d = dayjs(new Date());
        GetIncorrectCategoryTransactionsWithParameters(getActiveStartDate(d), getActiveEndDate(d), props.property, "", handleDoneGettingCategoryRows);
    }, [props.property, props.month, props.year, props.count])

    const handleDataUpdate = (data) => {
        setMonths(data.months);
        setRows(data.rows);
        setLoading(false);
    }

    const handleDoneGettingCategoryRows = (values) => {
        props.updateCategoryError(values.length);
    }

    return (
      <TableContainer component={Paper} sx={{marginBottom: '5vh', overflowX: "auto", overflowY: 'initial'}}>
        {loading ? (<LinearProgress />) : (
        <Table stickyHeader sx={{ minWidth: 650}} size="small" aria-label="a dense table">
          <TableHead >
            <TableRow
            key={props.property}>
            <TableCell 
                style={{
                    fontWeight: '700',
                    position: 'sticky',
                    zIndex: 900,
                    left: 0,
                    background: 'white',
                }} 
                key={props.monthyear}
                // style={{
                    
                //     left: 0,
                //     background: 'white',
                //     zIndex: 800,
                // }}>
                >{props.property} T12 Ending {props.monthyear}</TableCell>
                {months.map((m) => {
                    return <TableCell align="right" key={m.title}>{m.title}</TableCell>
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((section) => {
                return (
                    <SectionWrapper key={`Section Wrapper ${section.title}`} section={section} expanded={section.expanded}></SectionWrapper>
                )
                // {section.header && (<TableRow 
                //     key={section.title}
                //     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                //     ></TableRow>)}
            })}
          </TableBody>
        </Table>)}
      </TableContainer>
    );
  }