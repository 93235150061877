import * as React from 'react';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AddSingleIncome from '../Drive/add_single_income';
import AddRecurringIncome from '../Drive/add_recurring_income';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { save } from '../Redux/property_slice';
import "./mui_override.css";
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '150px',
    // height: '31px',
    paddingRight: '2vw',
    paddingBottom: '1vw'
  },
  textField2: {
    width: '150px',
    // height: '31px',
    paddingRight: '2vw',
  },
  propertyDropdownContainer: {
    width: '200px',
    paddingRight: '2vw',
    // height: '100%'
  },
  propertyDropdown: {
    paddingRight: '2vw',
    // height: '100%'
  },
  select: {
    textAlign: 'middle',
    // height: '32px',
    justifyContent: 'center',
  },
  property: {
    // height: '40px',
  },
  paper: {
    width: '92%', 
    padding: '2vw',
  },
  amountField: {
    paddingRight: '10px',
    width: '150px',
  },
  submitButton: {
    marginTop: '15px',
    marginLeft: '1vw',
  }
}));


export default function IncomeUpload(props) {
    const dispatch = useDispatch();
    const [recurringPayment, setRecurringPayment] = React.useState(false);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [property, setProperty] = React.useState(useSelector(state => state.property.value))
    const [category, setCategory] = React.useState("");
    const [amount, setAmount] = React.useState(0);
    const [frequency, setFrequency] = React.useState("weekly");
    const [alert, setAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("Successfully Uploaded Income");
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("Field missing");
    const [uploading, setUploading] = React.useState(false);
    const [iscredit, setIsCredit] = React.useState(false);
    const classes = useStyles();


    const handleDropdownChange = (event, value) => {
        setProperty(value);
        dispatch(save(value));
    }

    const handleCategoryChange = (event, value) => {
        setCategory(value);
    }

    const handleFrequencyChange = (event, value) => {
        setFrequency(value);
    }
    
    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    }

    const handleSubmitDone = () => {
        setAlert(true);
        setUploading(false);
    }

    const isValid = (val) => {
        return val != "" && val != undefined;
    }

    const submitIncome = () => {
        if (recurringPayment) {
            if (!isValid(startDate) || !isValid(endDate) || !isValid(property) || !isValid(amount) || !isValid(frequency) || !isValid(category)) {
                let error = "Missing Field(s):";
                if (!isValid(startDate)) {
                    error += " Start Date,"
                }
                if (!isValid(endDate)) {
                    error += " End Date,"
                }
                if (!isValid(property) ) {
                    error += " Property,"
                }
                if (!isValid(amount)) {
                    error += " Amount,"
                }
                if (!isValid(frequency)) {
                    error += " Frequency,"
                }
                if (!isValid(category)) {
                    error += " Category,"
                }
                setErrorMessage(error);
                if (alert) {
                    setAlert(false);
                }
                setError(true);
            }
            else {
                if (error) {
                    setError(false);
                }
                if (alert) {
                    setAlert(false);
                }
                let amount_message = iscredit ? `credit of ${amount}` : `withdrawal of -$${amount}`;
                let message = `Added ${frequency} recurring ${amount_message} to ${property} from ${startDate} to ${endDate}`;
                setUploading(true);
                setAlertMessage(message);
                let acc_amount = iscredit ? amount: -1*amount;
                AddRecurringIncome(dayjs(startDate).format('MM-DD-YYYY'), dayjs(endDate).format('MM-DD-YYYY'), property, acc_amount, frequency, category, handleSubmitDone);
            }
        }
        else {
            if (!isValid(startDate) || !isValid(property) || !isValid(amount) || !isValid(category)) {
                let error = "Missing Field(s):";
                if (!isValid(startDate)) {
                    error += " Start Date,"
                }
                if (!isValid(property) ) {
                    error += " Property,"
                }
                if (!isValid(amount)) {
                    error += " Amount,"
                }
                if (!isValid(category)) {
                    error += " Category,"
                }
                setErrorMessage(error);
                if (alert) {
                    setAlert(false);
                }
                setError(true);
            }
            else {
                let amount_message = iscredit ? `credit of ${amount}` : `withdrawal of -$${amount}`;
                let message = `Added one time ${amount_message} to ${property} on ${startDate}`;
                if (error) {
                    setError(false);
                }
                if (alert) {
                    setAlert(false);
                }
                setUploading(true);
                setAlertMessage(message);
                let acc_amount = iscredit ? amount: -1*amount;
                AddSingleIncome(dayjs(startDate).format('MM-DD-YYYY'), property, acc_amount, category, handleSubmitDone);
            }
        }
    }


    return (
        <div style={{paddingLeft: '4vw', paddingBottom: '2vh'}}>
            <Box style={{display: 'flex', alignItems: 'center'}}>
                <Grid item>One Time</Grid>
                <Grid item>
                    <Switch
                    checked={recurringPayment} // relevant state for your case
                    onChange={() => setRecurringPayment(!recurringPayment)} // relevant method to handle your change
                    value="checked" // some value you need
                    />
                </Grid>
                <Grid item style={{paddingRight: '20px'}}>Recurring</Grid>
                <Divider orientation="vertical" variant="middle" flexItem sx={{borderRightWidth: 2}}/>
                <Grid item style={{paddingLeft: '20px'}}>Withdrawal</Grid>
                    <Grid item>
                        <Switch
                        checked={iscredit} // relevant state for your case
                        onChange={() => setIsCredit(!iscredit)} // relevant method to handle your change
                        value="checked" // some value you need
                        />
                    </Grid>
                <Grid item>Deposit</Grid>
                </Box>
            <Paper className={classes.paper}>
            <Collapse in={alert}>
                <Alert
                severity="success"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setAlert(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {alertMessage}
                </Alert>
            </Collapse>
            <Collapse in={error}>
                <Alert
                severity="error"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setError(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {errorMessage}
                </Alert>
            </Collapse>
            <Box component="div" sx={{ display: 'inline' }}>
                <TextField
                    id="date"
                    label={recurringPayment ? "Start Date" : "Date"}
                    type="date"
                    onChange={handleStartDateChange}
                    defaultValue={startDate}
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                {recurringPayment && (
                    <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    onChange={handleEndDateChange}
                    defaultValue={endDate}
                    className={classes.textField2}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                )}
                {recurringPayment && ( 
                // <FormControl className={classes.propertyDropdownContainer} variant="standard">
                //     <InputLabel className={classes.propertyDropdown} id="demo-simple-select-standard-label">Frequency</InputLabel>
                //     <Select
                //         labelId="demo-simple-select-standard-label"
                //         id="demo-simple-select-standard"
                //         value={frequency}
                //         label="Frequency"
                //         onChange={handleFrequencyChange}
                //         className={classes.select}
                //         >
                //         <MenuItem value={"weekly"}>weekly</MenuItem>
                //         <MenuItem value={"biweekly"}>biweekly</MenuItem>
                //         <MenuItem value={"monthly"}>monthly</MenuItem>
                //     </Select>
                // </FormControl>
                <FormControl className={classes.propertyDropdownContainer} variant="standard">
                <Autocomplete
                  value = {frequency}
                  id="combo-box-demo"
                  options={['weekly', 'biweekly', 'monthly']}
                  onChange={handleFrequencyChange}
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="Frequency" InputLabelProps={{ shrink: true }}/>}
                />   
                </FormControl>
                )}
                <FormControl className={classes.propertyDropdownContainer} variant="standard">
                <Autocomplete
                  value = {property}
                  id="combo-box-demo"
                  options={props.properties}
                  onChange={handleDropdownChange}
                  disableClearable
                  renderInput={(params) => <TextField {...params} label="Property" InputLabelProps={{ shrink: true }}/>}
                />   
                </FormControl>
                <FormControl className={classes.propertyDropdownContainer} variant="standard">
                    <Autocomplete
                    value = {category}
                    id="combo-box-demo"
                    options={props.categories}
                    onChange={handleCategoryChange}
                    disableClearable
                    renderInput={(params) => <TextField {...params} label="Category" InputLabelProps={{ shrink: true }}/>}
                    />   
                </FormControl>
                <TextField
                    id="standard-number"
                    label="Amount"
                    type="number"
                    onChange={handleAmountChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">${iscredit ? "":"—"}</InputAdornment>,
                      }}
                    className={classes.amountField}
                />
                {uploading ? (<CircularProgress/>):(                
                <Button 
                className={classes.submitButton}
                variant="contained"
                onClick={submitIncome}
                > 
                <Tooltip title="Add transaction(s)">
                    <ArrowForwardIcon sx={{fontSize:30}}/>
                </Tooltip>
                </Button>)}

            </Box>
            </Paper>
        </div>
    )
}