import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import FilterCategories from '../Bodies/filter_categories';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InputAdornment from '@mui/material/InputAdornment';
import UpdateAmount from '../Drive/update_amount';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkIcon from '@mui/icons-material/Work';
import UpdateReimb from '../Drive/update_reimb';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTransaction from '../Drive/delete_transaction';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function isIncluded(row) {
  return row.include === "yes";
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, showExcluded) {

  if (!showExcluded) {
    const stabilized = array.filter(isIncluded)
    const s = stabilized.map((el, index) => [el, index]);
    s.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return s.map((el) => el[0]);
  }
  else {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: 'include',
    numeric: false,
    disablePadding: true,
    label: 'Include',
  },
  {
    id: 'date',
    type: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'property',
    numeric: false,
    disablePadding: false,
    label: 'Property',
  },
  {
    id: 'Category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  // {
  //   id: 'payableTo',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Payable To',
  // },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'reimbursement',
    numeric: false,
    disablePadding: true,
    label: 'Reimb',
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: false,
    label: 'Account',
  },
  {
    id: 'source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'link',
    numeric: false,
    disablePadding: false,
    label: 'File',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const [uploading, setUploading] = React.useState(false);

  const markInclude = () => {
    props.updateSelected("yes");
  }

  const markExclude = () => {
    props.updateSelected("");
  }

  const handleUploadingDone = () => {
    setUploading(false);
    props.handleDone();
  }

  const saveTransactions = () => {
    setUploading(true);
    props.save(handleUploadingDone);
  }


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
          <Tooltip title="Clear selected" placement="right">
            <Button 
            sx={{padding: 0, minWidth: 0}}
            onClick={() => props.setSelected([])}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Button 
          variant="contained" 
          color="success"
          onClick={markInclude}
          >
              Include 
          </Button>
          <Button 
          variant="contained" 
          color="info"
          onClick={markExclude}
          >
              Exclude
          </Button>
        </Box>
      ) : (
        <Box style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
        <Typography
          // sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
        {props.importButton && !uploading && 
        (<>  
          <Button color="info" variant="contained" onClick={saveTransactions}>
            Import
            </Button>
          <Button> 
            <Tooltip title="Exit without importing">
              <ExitToAppIcon 
              sx={{fontSize: 40}}
              onClick={props.handleDone}
              />
            </Tooltip>
            </Button>
          </>)
          }
          {uploading && 
        (<CircularProgress/>)}
        </Box>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function AmountInput(props) {
  const [editing, setEditing] = React.useState(false);
  const [amount, setAmount] = React.useState(props.amount);
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => (
    setAmount(props.amount)
  ), [props.amount])

  var formatting_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
 }

 const handleChange = (event) => {
    setAmount(event.target.value);
 }

 const handleDone = (res) => {
  if (res.succeeded) {
    setAmount(parseFloat(res.amount));
  }
  setEditing(false);
  setUpdating(false);
 }

 const keyPress = (event) => {
  if (event.keyCode === 13) {
    setUpdating(true);
    UpdateAmount(props.id, amount, handleDone);
  }
  if (event.keyCode === 27) {
    setEditing(false);
  }
 }

  return (
    <>
    { updating ? (<CircularProgress sx={{float: 'right'}}/>):
    
    (editing ? (
      <TextField
      id="standard-number"
      sx={{minWidth: '80px', width: '50%', float: 'right', paddingTop: '10px', }}
      defaultValue={amount}
      type="number"
      variant="standard"
      onChange={handleChange}
      onKeyDown={keyPress}
      InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    ):(
      <TableCell 
        align="right"
        onClick = {() => setEditing(!editing && !props.importButton)}
        >{amount.toLocaleString('en-US', formatting_options )}
      </TableCell>
    ))
    } 
    </>
  )
}

function CategoryDropdown(props) {
    const [category, setCategory] = React.useState(props.category);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
      setCategory(props.category);
    }, [props.category]);

    React.useEffect(() => {
      setLoading(props.loading);
    }, [props.loading]);

    const handleDone = (category) => {
        setCategory(category);
        setLoading(false);
    }

    const handleMultipleDone = (category) => {
      props.updateCategoryForRows(category);
      setLoading(false);
    }

    const handleCategoryChange = (event, value) => {
        if (props.numSelected === 0) {
          props.UpdateCategory([props.id], value, handleDone);
          setLoading(true);
        }
        else {
          props.UpdateCategory(props.selected, value, handleMultipleDone);
          setLoading(true);
        }
    };

    return (
        <div style={{height: '45px'}}>
        {loading ? (<CircularProgress/>) :(
          (props.numSelected === 0 || props.isItemSelected) ? (
          <Autocomplete
          disablePortal
          value = {category}
          id="combo-box-demo"
          options={props.categories}
          sx={{ 
            minWidth: 200, 
            "& .MuiOutlinedInput-root": {
              paddingRight: "15px!important",
              paddingTop: '0px !important',
              paddingBottom: '0px !important',
              fontSize: 14,
            },}}
          onChange={handleCategoryChange}
          disableClearable
          PaperComponent={({ children }) => (
            <Paper style={{ background: '#fbfbfb', width: 300 }}>{children}</Paper>
          )}
          renderInput={(params) => <TextField
            sx={{"& .Mui-error": {
              position: 'absolute',
              marginTop: '0px',
            }}}
            error={!props.categories.includes(category)}
            helperText={!props.categories.includes(category) ? "Invalid Category": ""}
            {...params} 
            />}
        /> ):(<div style={{paddingLeft:'15px', paddingTop: '13px'}}>{category}</div>)
        )}
        </div>
    )
}

function Reimbursementcell(props) {
  const [reimbState, setReimbState] = React.useState(props.state === "yes");
  const [isHovering, setIsHovering] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  

  React.useEffect(() => {
    setReimbState(props.state === "yes");
  }, [props.state])

  const updateReimbursement = (res) => {
    if (res.succeeded) {
      setReimbState(!reimbState);
    }
    setUpdating(false);
  }

  const unmarkReimb = () => {
    setUpdating(true);
    UpdateReimb("", props.id, updateReimbursement)
  }

  const markReimb = () => {
    setUpdating(true);
    UpdateReimb("yes", props.id, updateReimbursement)
  }

  return (
    <>
    {props.importButton ? <TableCell>
      <></>
    </TableCell>:
    <TableCell 
    align="left" 
    key="reimbursement"
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
    >
      {updating ? <CircularProgress /> : (
      reimbState ? 
      (<IconButton 
        style={{padding: 0}}
        onClick={unmarkReimb}
      >
        <Tooltip  title="Unmark reimbursement">
        <WorkIcon 
        sx={{color: isHovering ? '#1976d2': 'green'}}/>
        </Tooltip>
      </IconButton>):
      (isHovering ? (<IconButton 
      style={{padding: 0}}
      onClick={markReimb}
      >
        <Tooltip  title="Mark as reimbursement">
        <WorkOutlineIcon 
        />
        </Tooltip>
      </IconButton>):(<></>)))}
    </TableCell>}
    </>
  )
}

function DeleteTransactionComponent(props) {
  const [updating, setUpdating] = React.useState(false);

  const handleDelete = () => {
    setUpdating(true);
    DeleteTransaction(props.id, doneDeleting);
  }

  const doneDeleting = (values) => {
    if (values.succeeded) {
      props.setCount(props.count+1);
    }
    else {
      setUpdating(false);
    }
  }

  return (
    <TableCell sx={{padding: 0, border: 'none'}}>
      {updating ? <CircularProgress/>:(
      <Tooltip title={"Delete transaction"}>
        <IconButton 
        sx={{padding: 0}}
        onClick={handleDelete}
        >
            <DeleteIcon />
        </IconButton>
      </Tooltip>)}
    </TableCell>
  )
}

function TransactionRow(props) {
  const [include, setInclude] = React.useState(props.include);
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(() => {
    setInclude(props.include);
  },[props.include])

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  const changeIncludeStatus = () => {
    setInclude(include === "" ? "yes" : "");
  }

  const openFileWindow = () => {
    let urls = props.row.url.split(" ");
    for (let i = 0; i < urls.length; i++) {
      if (!urls[i].startsWith('http')) {
        window.open(`https://drive.google.com/file/d/${urls[i]}/view?usp=drivesdk`);
      }
      else {
        window.open(urls[i]);
      }
    }
    
  }

  const receiptString = (urls) => {
    return urls.split(" ").length;
  }


  return (
    <TableRow
        hover
        role="checkbox"
        aria-checked={props.isItemSelected}
        tabIndex={-1}
        key={props.row.id}
        selected={props.isItemSelected}
        style={{backgroundColor: include === "yes" ? '#eef6eb' : 'white', height: 40}}
      >
        <TableCell 
        padding="checkbox"
        key="checkbox"
        onClick={(event) => props.handleClick(event, props.row.id)}
        >
          <Checkbox
            color="primary"
            checked={props.isItemSelected}
            inputProps={{
              'aria-labelledby': props.labelId,
            }}
          />
        </TableCell>
        <TableCell 
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            key="IncludeExclude"
        >
        {!props.isItemSelected && !props.updatingSelected && <IncludeExclude include={include} hovering={isHovering} id={props.row.id} updateIncludeStatus={changeIncludeStatus} UpdateIncludeSingle={props.UpdateIncludeSingle}/>}
        {props.isItemSelected && !props.updatingSelected &&  (<DeleteTransactionComponent id={props.row.id} rows={props.rows} setRows={props.setRows} selected={props.selected} setSelected={props.setSelected} count={props.count} setCount={props.setCount}/>)}
        {props.isItemSelected && props.updatingSelected && <CircularProgress />}
        </TableCell>
        <TableCell align="left" key="date">{props.row.date}</TableCell>
        <TableCell align="left" key="property">{props.row.property}</TableCell>
        <TableCell align="left" key="category">
          {/* {row.category} */}
            <CategoryDropdown category={props.row.category} id={props.row.id} categories={props.categories} {...props}/>
          </TableCell>
        {/* <TableCell align="left">{row.payableTo}</TableCell> */}
        {/* <TableCell align="right">{props.row.amount.toLocaleString('en-US', formatting_options )}</TableCell> */}
        <AmountInput amount={props.row.amount} id={props.row.id} importButton={props.importButton}/>
        <Reimbursementcell state={props.row.reimbursement} id={props.row.id} importButton={props.importButton}/>
        <TableCell align="left" key="account">{props.row.account}</TableCell>
        <TableCell align="left" key="source">{props.row.source}</TableCell>
        <TableCell align="left" key="description">{props.row.description}</TableCell>
        <TableCell align="left" key="url">
          {props.row.url !== "" && (
            <>
          <IconButton
          onClick={openFileWindow}
          >
            <Tooltip title={"View Receipt/ File"}>
              <ReceiptIcon/>
            </Tooltip>
          </IconButton>
          {receiptString(props.row.url)}
          </>
          )}
        </TableCell>
      </TableRow>
  )
}

function IncludeExclude(props) {
  const [changing, setChanging] = React.useState(false);

  const changeIncluded = (value, id) => {
    setChanging(true);
    props.UpdateIncludeSingle(value, id, doneChanging);

  } 

  const doneChanging = (res) => {
    if (res.succeeded) {
      props.updateIncludeStatus();
      setChanging(false);
    }
    else {
      setChanging(false);
    }
  }

  return (
    <>
    {!changing && !props.hovering && props.include && <CheckIcon/>}
    {!changing && props.hovering && (props.include === "yes" ? (
      <Tooltip title="Exclude" placement="left">
        <Button
        onClick={() => changeIncluded("", props.id)}
        style={{padding: 0, minWidth: 0}}
        >
          <ClearIcon/>
        </Button>
      </Tooltip>
      ):(
      <Tooltip title="Include" placement="left">
        <Button
        onClick={() => changeIncluded("yes", props.id)}
        style={{padding: 0, minWidth: 0}}
        >
          <AddIcon/>
        </Button>
      </Tooltip>
      ))}
      {changing && <CircularProgress />}
    </>
  )
}

export default function TransactionList(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const dense = true;
  const [showExcluded, setShowExcluded] = React.useState(false);
  const [updatingSelected, setUpdatingSelected] = React.useState(false);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setUpdatingSelected(props.gettingRows);
  },[props.gettingRows])

  React.useEffect(() => {
    setShowExcluded(props.showExcluded);
  },[props.showExcluded])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const doneUpdatingSelected = (values) => {
    setUpdatingSelected(false);
    setSelected([]);
    props.setRows(values);
  }


  const updateSelected = (value) =>{
    setUpdatingSelected(true);
    props.UpdateInclude(selected, value, doneUpdatingSelected)
  }

  const handleShowExcludedChange = (event) => {
    setShowExcluded(event.target.checked);
  }

  const handleCategoryChangeForRows = (category) => {
    let newRows = [...props.rows];
    let selectedSet = new Set(selected);
    for (let i = 0; i < newRows.length; i++) {
      if (selectedSet.has(newRows[i].id)) {
        newRows[i].category = category;
      }
      props.setRows(newRows);
    }
  }


  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2, marginBottom: '75px'}}>
        <EnhancedTableToolbar numSelected={selected.length} setSelected={setSelected} updateSelected={updateSelected} {...props}/>
        <FilterCategories downloadOptions={props.downloadOptions} count={count} showExcluded={showExcluded} disableCategory={props.disableCategory} getWithParameters={props.getWithParameters} categories={props.categories} updateCategories={props.updateCategories} properties={props.properties} setRows={doneUpdatingSelected} updatingSelection={setUpdatingSelected} handleShowExcludedChange={handleShowExcludedChange} length={props.rows.length} importButton={props.importButton}/>
          <Divider />
        <TableContainer sx={{overflowX: "initial"}}>
          <Table
            stickyHeader
            sx={{ minWidth: 750}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {(updatingSelected ) && <TableCell colSpan={headCells.length+1}><LinearProgress/></TableCell>}
              {!updatingSelected && props.rows.length === 0 && <TableCell colSpan={6}>No Transactions. You may be using filters that are too restrictive.</TableCell>}
              {stableSort(props.rows, getComparator(order, orderBy), showExcluded)
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TransactionRow row={row} count={count} setCount={setCount} isItemSelected={isItemSelected} numSelected={selected.length} selected={selected} setSelected={setSelected} updateCategoryForRows={handleCategoryChangeForRows} labelId={labelId} handleClick={handleClick} updatingSelected={updatingSelected} include={row.include} {...props}/>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
