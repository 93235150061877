import MainNav from "../Navigation/mainnav";
import {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Spin } from 'antd';
import SelectSource from "../Gapi/SelectSource";
import { useNavigate } from "react-router-dom";
import ListDocuments from '../Gapi/ListDocuments';
import { style } from '../Gapi/SelectSource/styles';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import IncomeUpload from "../Bodies/income_upload";
import GetProperties from "../Drive/get_properties";
import GetCategories from "../Drive/get_categories";

const NewDocumentWrapper = styled.div`
  ${style}
`;


export default function Upload(props) {
    const navigate = useNavigate();
    const [signedIn, setSignedIn] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [signedInUser, setSignedInUser] = useState();
    const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(false);
    const [listDocumentsVisible, setListDocumentsVisibility] = useState(false);
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (window.gapi.auth2 == undefined || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
            navigate('/signin')
        }
        else {
            setSignedIn(true);
            setSignedInUser(window.gapi.auth2.getAuthInstance().currentUser.le.wt);
            listFiles();
            GetProperties(setProperties);
            GetCategories(setCategories);
        }
    }, []);

    const onClose = () => {
        setListDocumentsVisibility(false);
      };

    const handleSignOutClick = (event) => {
        setListDocumentsVisibility(false);
        window.gapi.auth2.getAuthInstance().signOut();
      };
    

    const listFiles = (searchTerm = null) => {
        setIsFetchingGoogleDriveFiles(true);
        window.gapi.client.drive.files
          .list({
            'q': "'1upB56njtdDrdtnLJf-3y3TnaIBau5koS' in parents",
            'pageSize': 10,
            'fields': 'files(id, name, createdTime, modifiedTime, version, trashed)',
          })
          .then(function (response) {
            setIsFetchingGoogleDriveFiles(false);
            setListDocumentsVisibility(true);
            const res = JSON.parse(response.body);
            let filtered = res.files.filter((f) => !f.trashed);
            setDocuments(filtered);
          })
      };

    const deleteFile = (id) => {
      setIsFetchingGoogleDriveFiles(true);
      window.gapi.client.drive.files
        .delete({
          'fileId': id,
        })
        .then(function (response) {
          const res = response;
          listFiles();
        })
    };

    const updateCategories = (values) => {
        setCategories(values);
    }


    return (
        <>
        {signedIn && (
            <>
                <MainNav />
                <Divider sx={{ borderBottomWidth: 3 }}/>
                <Typography variant="h5" style={{paddingLeft: '4vw', paddingTop: '1vw'}}>Add Transactions</Typography>
                <IncomeUpload properties={properties} categories={categories}/>
                <Divider sx={{ borderBottomWidth: 3 }}/>
                <Typography variant="h5" style={{paddingLeft: '4vw', paddingTop: '1vw'}}>Import Bank Statements </Typography>
                <NewDocumentWrapper>
                    <ListDocuments
                    visible={listDocumentsVisible}
                    onClose={onClose}
                    documents={documents}
                    listFiles={listFiles}
                    deleteFile={deleteFile}
                    signedInUser={signedInUser}
                    onSignOut={handleSignOutClick}
                    isLoading={isFetchingGoogleDriveFiles}
                    categories={categories}
                    updateCategories={updateCategories}
                    properties={properties}
                    />
                </NewDocumentWrapper>
            </>
        )}
        </>
    )
}
