
async function  UpdateAmount(id, amount, handleDone) {
    var url = `https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?method=updateAmount&amount=${amount}&id=${id}`;
    fetch(url).then(response => { 
        let result = response.json();
        result.then(res => {
            handleDone(res);
        })
    })
}

export default UpdateAmount;