async function  UpdateIncludeInSheet(selected, value, update) {
    var url = `https://script.google.com/macros/s/AKfycbzMUz93FecTWdcMi6Z-JdRWhZv1drLMn6vq5pq78BVE48cjtd6aZbEHEXZQ6XmIfFSbFw/exec?method=updateInclude&value=${value}`
    for(let i = 0; i < selected.length; i++) {
        url += `&n=${selected[i]}`
    }
    fetch(url).then(response => { 
        let result = response.json();
        result.then(result => update(result))
    })
}

export default UpdateIncludeInSheet