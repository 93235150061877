
async function  SaveTransactions(handleDone) {
    var url = `https://script.google.com/macros/s/AKfycbzMUz93FecTWdcMi6Z-JdRWhZv1drLMn6vq5pq78BVE48cjtd6aZbEHEXZQ6XmIfFSbFw/exec?method=save`
    fetch(url).then(response => { 
        let result = response.json();
        result.then(res => {
            if (res.succeeded) {
                handleDone()
            }
        })
    })
}

export default SaveTransactions