import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, Table, Tooltip, Button } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import GetTransactionsFromSheet from '../../Drive/get_transactions_from_sheet';
import TransactionList from "../../Bodies/transactions_list";
import UpdateIncludeInSheet from '../../Drive/update_include_in_sheet';
import UpdateIncludeSingleInSheet from '../../Drive/update_include_single_in_sheet';
import SaveTransactions from '../../Drive/save_transactions';
import UpdateCategoryInSheets from '../../Drive/update_category_in_sheets';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from "react-redux";
import { save } from '../../Redux/property_slice';
import './ant.css';

// const { Search } = Input;

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  modal: {
    width: '90vw', 
    paddingTop: '20px',
  },
  loadingContainer: {
    height: '100vh',
    textAlign: 'center',
    paddingTop: '20vh'
  },
  loadingBody: {
    verticalAlign: 'middle',
  }
}));

const ListDocuments = ({ visible, onClose, documents = [], listFiles, deleteFile, signedInUser, onSignOut, isLoading, categories, updateCategories, properties}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [processingBankStatement, setProcessingBankStatement] = useState(false);
  const [uploadingBankStatement, setUploadingBankStatement] = useState(false);
  const [rows, setRows] = useState([]);
  const [gettingRows, setGettingRows] = useState(false);
  const [sheetId, setSheetId] = useState("");
  const [property, setProperty] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(false);
  // const [categories, setCategories] = useState([]);


  const uploadFile = (event) => {
    event.preventDefault();
    // only 1 file upload allowed at a time
    let f = event.target.files[0];
    let name = f.name;
    const form = new FormData();
    let accessToken = window.gapi.auth.getToken().access_token; 
    let parentId = ['1upB56njtdDrdtnLJf-3y3TnaIBau5koS'];
    setProcessingBankStatement(true);
    setUploadingBankStatement(true);

    form.append(
      "metadata",
      new Blob(
        [
          JSON.stringify({
            name: name,
            mimeType: 'application/vnd.google-apps.spreadsheet',
            parents: parentId,
          })
        ],
        { type: "application/json" }
      )
    );
    form.append("file", f);
    return fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=name,webViewLink,id,mimeType",
      {
        method: "POST",
        headers: { Authorization: "Bearer " + accessToken },
        body: form
      }
    ).then((response) => {
      let res = response.json();
      res.then((result) => {
        setUploadingBankStatement(false);
        event.target.value = null;
        setSheetId(result.id);
        // GetTransactionsFromSheet(result.id, setRows);
      }
      )

    });

  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text.split("::")[0]}</span>
    },
    {
      title: 'Uploaded On',
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (text) => <span>{moment(text).format('Do MMM YYYY HH:mm A')}</span>,
    },
    {
      title: 'Import Status',
      key: 'id',
      dataIndex: 'id',
      render: (text, record) => {
        let error = record.name.split("::").length === 1;
        return(
        <span>
          {error ? (
            <>
            <span>Failed</span>
            <Button type="primary" danger onClick={() => deleteFile(text)} style={{marginLeft: '10px'}}>
              DELETE
            </Button>
            </>
          ):(
            <span>Successful</span>
          )}
        </span>
      )},
    },
  ];

  const handlePropertySelect = (event, value) => {
    setProperty(value);
    dispatch(save(value));
  }

  const upload = () => {
    setSelectedProperty(true);
    setGettingRows(true);
    GetTransactionsFromSheet(sheetId, property, doneGettingRows);
  }

  const doneGettingRows = (values) => {
    setRows(values);
    setGettingRows(false);
  }

  const handleDoneUploading = () => {
    setProcessingBankStatement(false);
    setSelectedProperty(false);
    setRows([]);
    listFiles();
  }

  const saveAndClear = (f) => {
    SaveTransactions(f);
    setRows([]);
  }


  const uploadContainer = () => (    
    <Box className={classes.modal}>
      {uploadingBankStatement && (
        <div className={classes.loadingContainer}>
          <div className={classes.loadingBody}>
            <Typography variant="h3" >Uploading File to Google Drive</Typography>
            <CircularProgress size='5vw'/>
          </div>
        </div>
      )}
      {!uploadingBankStatement && !selectedProperty ?
        (<div className={classes.loadingContainer}>
        <div className={classes.loadingBody}>
          <Typography variant="h3" >Which property is this for?</Typography>
          <Autocomplete
              value = {property}
              id="combo-box-demo"
              options={properties}
              disableClearable
              sx={{width: 400, margin: 'auto', paddingTop: '5vh'}}
              onChange={handlePropertySelect}
              
              renderInput={(params) => <TextField {...params} label="Property" InputLabelProps={{ shrink: true }}/>}
            />
          {property !== "" && (
          <MuiButton 
            variant="contained" 
            onClick={upload}
            sx={{marginTop: '10px'}}
            ><ArrowForwardIcon/></MuiButton>
          )} 
        </div>
      </div>
      ):(
        <>
        <TransactionList showExcluded={true} gettingRows={gettingRows} updateCategories={updateCategories} rows={rows} setRows={setRows} categories={categories} properties = {properties} UpdateInclude={UpdateIncludeInSheet} UpdateIncludeSingle={UpdateIncludeSingleInSheet} importButton={true} save={saveAndClear} handleDone={handleDoneUploading} UpdateCategory={UpdateCategoryInSheets} UpdateCategoryInSheetstitle={"Bank Statement Upload"} title={`Import Bank Statement for ${property}`}/>
        </>
      )}
    </Box>
  );

  return (
    <>
      <Row gutter={16}>
        <Col span={1}></Col>
        <Col span={22}>
          <div style={{ marginBottom:20}}>
            <p>Signed In as: {`${signedInUser?.Ad} (${signedInUser?.cu})`}</p>
            <div>
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                onChange={uploadFile}
              />
              <Tooltip title="Accepted formats: .xls .csv" placement="right">
                <MuiButton type="submit" color="primary" variant="contained" component="div">
                  Upload Bank Statement
                </MuiButton>
              </Tooltip>
              {/* <Typography variant="caption" style={{paddingLeft: '10px'}}>Accepted formats: .xls .csv </Typography> */}
              </label>
              </div>
          </div>
          <Table
            className="table-shadow"
            rowClassName={(record) => record.name.split("::").length === 1 ? 'table-row-failed' :  'table-row-success'}
            columns={columns}
            dataSource={documents}
            pagination={{ simple: true }}
            loading={isLoading}
          />
        </Col>
        <Col span={1}></Col>
      </Row>
      <Drawer
        anchor={'right'}
        open={processingBankStatement}
      >
        {uploadContainer()}
      </Drawer>
      </>
  );
};

export default ListDocuments;