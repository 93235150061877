import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import GetTransactions from '../Drive/get_transactions';
import GetCategories from '../Drive/get_categories';
import UpdateInclude from '../Drive/update_include';
import UpdateCategory from '../Drive/update_category';
import MainNav from '../Navigation/mainnav';
import { useNavigate } from 'react-router-dom';
import TransactionList from '../Bodies/transactions_list';
import GetProperties from '../Drive/get_properties';
import GetReimbursementTransactionsWithParameters from '../Drive/get_reimbursement_transactions_with_parameters';
import UpdateIncludeSingle from '../Drive/update_include_single';
import dayjs from 'dayjs';
import {getActiveStartDate, getActiveEndDate} from '../Util/get_dates';
import {useSelector} from "react-redux";


export default function Reimbursements() {
  const [rows, setRows] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [signedIn, setSignedIn] = React.useState(false);
  const [properties, setProperties] = React.useState([]);
  const initialProperty = useSelector((state) => state.property.value);
  const [gettingRows, setGettingRows] = React.useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (window.gapi.auth2 == undefined || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      navigate('/signin')
    }
    else {
        setSignedIn(true);
        GetCategories(setCategories);
        GetProperties(setProperties);
        setGettingRows(true);
        let d = dayjs(new Date());
        GetReimbursementTransactionsWithParameters(getActiveStartDate(d), getActiveEndDate(d), initialProperty, "", handleDoneGettingRows);
    }
  }, []);

  const handleDoneGettingRows = (values) => {
    setRows(values);
    setGettingRows(false);
  }




  return (
    <Box >
    <MainNav/>
      {signedIn && (
        <Box sx={{paddingRight: '2vw', paddingLeft: '2vw'}}>
          <TransactionList downloadOptions disableCategory gettingRows={gettingRows} rows={rows} setRows={setRows} categories={categories} properties={properties} getWithParameters={GetReimbursementTransactionsWithParameters} UpdateInclude={UpdateInclude} UpdateIncludeSingle={UpdateIncludeSingle} UpdateCategory={UpdateCategory} title={"Reimbursements"}/>
        </Box>
      )}
    </Box>
  );
}