
async function  UpdateCategory(ids, category, handleDone) {
    let cat = encodeURIComponent(category)
    var url = `https://script.google.com/macros/s/AKfycbxY0X5FX1ibq256o5XUBnTeWSZqXbsRey9btZx2wl1-Ham6ALQJ-ntUF8X1KdTE6x3OXw/exec?method=updateCategory&category=${cat}`
    for (let i = 0; i < ids.length; i++) {
        url += `&id=${ids[i]}`
    }
    fetch(url).then(response => { 
        let result = response.json();
        result.then(res => {
            if (res.succeeded) {
                handleDone(category)
            }
        })
    })
}

export default UpdateCategory