import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import Upload from './Components/Pages/upload.js';
import SelectableTransactions from './Components/Pages/selectable-transactions.js'
import reportWebVitals from './reportWebVitals';
import SignIn from './Components/Pages/signin';
import Dashboard from './Components/Pages/dashboard';
import Audit from './Components/Pages/audit';
import ReceiptUpload from './Components/Pages/receipt_upload';
import store from './store';
import { Provider } from 'react-redux';
import Reimbursements from './Components/Pages/reimbursements';

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<ReceiptUpload/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/transactions" element={<SelectableTransactions/>} />
          <Route path="/upload" element={<Upload/>} />
          <Route path="/signin" element={<SignIn/>} />
          <Route path="/audit" element={<Audit/>}/>
          <Route path="/receipts" element={<ReceiptUpload/>}/>
          <Route path="/reimb" element={<Reimbursements />} />
        </Routes>
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
